import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../language/i18n";
import facilityService from "../../../services/facility/facilityService";
import SelectField from "../SelectField/SelectField";

const FacilitySelector = ({
  onSelect,
  preSelected,
  disabled,
  showHUL,
  customCssClass,
  referencedPrescriber,
  onSelectOnMount,
}) => {
  const translate = useTranslate();
  const appDataUser = useSelector(state => state.appData.user);
  const [storages, setStorages] = useState([]);

  useEffect(() => {
    facilityService
      .getFacilities(decodeURIComponent(referencedPrescriber))
      .then(response => setStorages(response.data));
  }, [referencedPrescriber]);

  const getPreSelected = () => {
    if (preSelected !== null && preSelected?.category?.value !== "0") {
      if (preSelected?.category?.value === "2") {
        return preSelected.id;
      } else {
        return preSelected?.id ? preSelected.id : preSelected;
      }
    }
    return null;
  };

  return (
    <>
      <SelectField
        options={
          storages?.length &&
          storages?.map(current =>
            Object.assign(current, {
              id: current?.id,
              value: current?.value,
              label: current?.label,
              category: current?.category,
              description: current?.address?.description,
              facilityName: `${current?.value} - ${current?.label}`,
            }),
          )
        }
        customSettings={{ display: "facilityName" }}
        label={showHUL ? appDataUser.company.id : translate("BASE_STORAGE")}
        disabled={disabled}
        customCssClass={customCssClass}
        selectedId={getPreSelected()}
        onSelect={onSelect}
        onSelectOnMount={onSelectOnMount}
        noResultLabel={"FACILITY_MISSING"}
      />
    </>
  );
};

FacilitySelector.propTypes = {
  onSelect: PropTypes.func,
  preSelected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
  showHUL: PropTypes.bool,
  customCssClass: PropTypes.string,
  referencedPrescriber: PropTypes.string,
  onSelectOnMount: PropTypes.bool,
};

export default FacilitySelector;
